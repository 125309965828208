import { defineStore } from "pinia";
import { useAsyncData } from "#app";

const config = useRuntimeConfig();
const myHeaders = new Headers();
myHeaders.append("Authorization", config.public.meilisearchToken);

export const useHighlightStore = defineStore("HighlightStore", {
  state: () => ({
    Highlights: ["x", "y", "z"],
  }),

  actions: {
    async getHighlights() {
      try {
        console.log("Fetching Highlights 🙋‍ ");
        const { data: highlights } = await useAsyncData("event", () =>
          $fetch(
            config.public.meilisearchUrl +
              "indexes/booker_db_index_prod/search?filter=highlight=1&attributesToRetrieve=id,title,dates,teaser_img,legacy_id",
            {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            }
          )
        );
        this.Highlights = highlights.value;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
